import { Box, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import React from "react";

const CIRPHeader = () => {
  const { id } = useParams();
  const isActiveButton = (path) => {
    return location.pathname === path;
  };
  return (
    <Box
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"space-between"}
      sx={{ marginBottom: 2 }}
    >
      {[
        "/process/cirp/:id/case-summary",
        "/process/cirp/:id/case-runs",
        "/process/cirp/:id/claims",
        "/process/cirp/:id/meetings",
        "/process/cirp/:id/participants",
        "/process/cirp/:id/documents",
      ].map((path, index) => (
        <Button
          key={index}
          component={Link}
          to={path.replace(":id", id)} // Replace 'yourId' with actual dynamic id
          variant="text"
          sx={{
            color: isActiveButton(path.replace(":id", id))
              ? "primary.main"
              : "text.primary",
            fontWeight: isActiveButton(path.replace(":id", id))
              ? "bold"
              : "normal",
            borderBottom: isActiveButton(path.replace(":id", id))
              ? "2px solid"
              : "none",
          }}
        >
          {path
            .split("/")
            .pop()
            .replace("-", " ")
            .replace(/^\w/, (c) => c.toUpperCase())}
        </Button>
      ))}
    </Box>
  );
};

export default CIRPHeader;
