import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import routes from "./services/routes";
import LoadingSpinner from "./components/LoadingSpinner"; // Custom spinner
import Other from "./context/Other";
import Mui from "./context/Mui";
import Bootstrap from "./context/Bootstrap";
import Icon from "./context/Icon";
import Leftsidebar from "./components/Leftsidebar";

function App() {
  return (
    <Other>
      <Mui>
        <Bootstrap>
          <Icon>
            <Router>
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  <Route element={<Leftsidebar />}>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={<route.component />}
                      />
                    ))}
                  </Route>
                </Routes>
              </Suspense>
            </Router>
          </Icon>
        </Bootstrap>
      </Mui>
    </Other>
  );
}

export default App;
