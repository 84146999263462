// CirpSummaryPage.js
import React from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import CIRPHeader from "../components/CIRPHeader";

const CirpSummaryPage = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const location = useLocation(); // Get current location to set active button

  const { id } = useParams();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const cirpTimeline = [
    {
      action: "Commencement of CIRP upon admission of application (T)",
      dueDateOrder: "10/08/1999",
      dueDateReceiptOrder: "17/04/2018",
    },
    {
      action: "Public announcement inviting claims (T+3)",
      dueDateOrder: "13/08/1999",
      dueDateReceiptOrder: "20/04/2018",
    },
    {
      action: "Submission of claims (T+14)",
      dueDateOrder: "24/08/1999",
      dueDateReceiptOrder: "01/05/2018",
    },
    {
      action: "Submission of claims (T+90)",
      dueDateOrder: "08/11/1999",
      dueDateReceiptOrder: "16/07/2018",
    },
    {
      action: "Verification of Claims (T+21)",
      dueDateOrder: "31/08/1999",
      dueDateReceiptOrder: "08/05/2018",
    },
    {
      action: "Verification of Claims (T+97)",
      dueDateOrder: "15/11/1999",
      dueDateReceiptOrder: "23/07/2018",
    },
    {
      action: "Application for appointment of AR (T+23)",
      dueDateOrder: "02/09/1999",
      dueDateReceiptOrder: "10/05/2018",
    },
    {
      action: "Report certifying constitution of CoC (T+23)",
      dueDateOrder: "02/09/1999",
      dueDateReceiptOrder: "10/05/2018",
    },
    {
      action: "1st meeting of the CoC (T+70)",
      dueDateOrder: "09/09/1999",
      dueDateReceiptOrder: "17/07/2018",
    },
  ];

  // Function to determine if the button is active

  return (
    <Box sx={{ padding: 3 }}>
      <CIRPHeader />
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="CIRP Timeline" />
        <Tab label="Debtors Profile" />
        <Tab label="CIRP Process" />
      </Tabs>

      {tabValue === 0 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Due Date (Order)</TableCell>
                <TableCell>Due Date (Receipt of Order)</TableCell>
                <TableCell>Custom Timeline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cirpTimeline.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.action}</TableCell>
                  <TableCell>{row.dueDateOrder}</TableCell>
                  <TableCell>{row.dueDateReceiptOrder}</TableCell>
                  <TableCell>
                    <Button variant="outlined" size="small">
                      Select Date
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default CirpSummaryPage;
