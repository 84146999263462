// AddCirpPage.js
import React, { useState } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  TextField,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";

const steps = ["CIRP Process", "Setup Support Team"];

const AddCirpPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    corporateDebtorName: "",
    cinNo: "",
    applicantName: "",
    applicantEmail: "",
    stage: "IRP",
    ncltOrderNo: "",
    dateOfReceiptOfOrder: dayjs().format("YYYY-MM-DD"),
    dateOfOrder: dayjs().format("YYYY-MM-DD"),
    applicationFilingDate: dayjs().format("YYYY-MM-DD"),
    defaultAmount: "",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [memberData, setMemberData] = useState({
    username: "",
    role: "",
    moduleAccess: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      // Handle form submission or completion
      console.log(formData);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleMemberChange = (e) => {
    setMemberData({ ...memberData, [e.target.name]: e.target.value });
  };

  const handleAddMember = () => {
    // Handle adding member logic here
    console.log("New Member:", memberData);
    handleDialogClose();
  };

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box component="form" noValidate sx={{ mt: 3 }}>
        {activeStep === 0 && (
          <>
            <Typography variant="h6" gutterBottom>
              CIRP Process
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Corporate Debtor Name"
              name="corporateDebtorName"
              value={formData.corporateDebtorName}
              onChange={handleChange}
              placeholder="Enter Corporate Debtor Name"
              variant="outlined"
            />
            <TextField
              fullWidth
              margin="normal"
              label="CIN NO"
              name="cinNo"
              value={formData.cinNo}
              onChange={handleChange}
              placeholder="Enter CIN NO"
              variant="outlined"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Applicant Name"
              name="applicantName"
              value={formData.applicantName}
              onChange={handleChange}
              placeholder="Enter Applicant Name"
              variant="outlined"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Applicant Email"
              name="applicantEmail"
              value={formData.applicantEmail}
              onChange={handleChange}
              placeholder="Enter Applicant Email"
              variant="outlined"
            />
            <TextField
              select
              fullWidth
              margin="normal"
              label="Stage"
              name="stage"
              value={formData.stage}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="IRP">IRP</MenuItem>
              <MenuItem value="CIRP">CIRP</MenuItem>
            </TextField>
            <TextField
              fullWidth
              margin="normal"
              label="NCLT Order No"
              name="ncltOrderNo"
              value={formData.ncltOrderNo}
              onChange={handleChange}
              placeholder="Enter NCLT Order No"
              variant="outlined"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Default Amount (INR)"
              name="defaultAmount"
              value={formData.defaultAmount}
              onChange={handleChange}
              placeholder="Enter Default Amount (INR)"
              variant="outlined"
              type="number"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Date Of Receipt Of Order"
              name="dateOfReceiptOfOrder"
              value={formData.dateOfReceiptOfOrder}
              onChange={handleChange}
              variant="outlined"
              type="date"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Date Of Order"
              name="dateOfOrder"
              value={formData.dateOfOrder}
              onChange={handleChange}
              variant="outlined"
              type="date"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Application Filing Date"
              name="applicationFilingDate"
              value={formData.applicationFilingDate}
              onChange={handleChange}
              variant="outlined"
              type="date"
            />
          </>
        )}

        {activeStep === 1 && (
          <>
            <Typography variant="h6" gutterBottom>
              Setup Support Team
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogOpen}
              sx={{ mb: 2 }}
            >
              Add Member
            </Button>
            {/* Here you can list current members if needed */}
          </>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </Button>
        </Box>
      </Box>

      {/* Dialog for adding a member */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Add Member</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            margin="normal"
            label="Select User"
            name="username"
            value={memberData.username}
            onChange={handleMemberChange}
            variant="outlined"
          >
            <MenuItem value="">Select User</MenuItem>
            <MenuItem value="user1">User 1</MenuItem>
            <MenuItem value="user2">User 2</MenuItem>
            <MenuItem value="user3">User 3</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="normal"
            label="Select Role"
            name="role"
            value={memberData.role}
            onChange={handleMemberChange}
            variant="outlined"
          >
            <MenuItem value="">select role</MenuItem>
            <MenuItem value="role1">Role 1</MenuItem>
            <MenuItem value="role2">Role 2</MenuItem>
            <MenuItem value="role3">Role 3</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="normal"
            label="Module Access"
            name="moduleAccess"
            value={memberData.moduleAccess}
            onChange={handleMemberChange}
            variant="outlined"
          >
            <MenuItem value="">Select Module Access</MenuItem>
            <MenuItem value="module1">Module 1</MenuItem>
            <MenuItem value="module2">Module 2</MenuItem>
            <MenuItem value="module3">Module 3</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddMember} color="primary">
            Add Member
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddCirpPage;
