import { Field, Formik, Form, ErrorMessage } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import React from "react";
import * as Yup from "yup";

import { OtherContext } from "./Context";
import { useQuery, useQueryClient } from "react-query";
// import dayjs from "dayjs";

const Other = ({ children }) => {

    const [pageName, setPageName] = React.useState("");
    const [subPageName, setSubPageName] = React.useState("");

    const cookiesDomain = "lawyercrm.multiplerevolution.online";

    return (
        <OtherContext.Provider
            value={{
                useQuery,
                useQueryClient,
                Field,
                Formik,
                Form,
                ErrorMessage,
                Yup,
                NavLink,
                useNavigate,
                pageName,
                setPageName,
                subPageName,
                setSubPageName,
                cookiesDomain
            }}
        >
            {children}
        </OtherContext.Provider>
    );
};

export default Other;
