import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const BreadcrumbsComponent = () => {
  const location = useLocation();

  // Create an array of path segments
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathSegments.map((segment, index) => {
        const path = `/${pathSegments.slice(0, index + 1).join("/")}`;

        return (
          <Link
            key={path}
            to={path}
            style={{ textDecoration: "none", color: "#374ead" }}
          >
            <Typography variant="body2">
              {segment.replace(/-/g, " ")}
            </Typography>
          </Link>
        );
      })}
      <Typography variant="body2" color="text.primary">
        {pathSegments[pathSegments.length - 1].replace(/-/g, " ")}
      </Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
