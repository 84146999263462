// src/routes.js

import { lazy } from "react";
import CirpSummaryPage from "../pages/CirpSummaryPage";
import AddCIRPPage from "../pages/AddCirpPage";
import CirpCaseRun from "../pages/CirpCaseRun";

// Lazy load the components
const CirpPage = lazy(() => import("./../pages/CirpPage"));
const LoginPage = lazy(() => import("./../pages/LoginPage"));
const DashboardPage = lazy(() => import("./../pages/DashboardPage"));
const NotFoundPage = lazy(() => import("./../pages/NotFoundPage"));

// Define routes as an array of objects
const routes = [
  {
    path: "/",
    component: LoginPage,
  },
  {
    path: "/process/cirp",
    component: CirpPage,
  },
  {
    path: "/process/cirp/add",
    component: AddCIRPPage,
  },
  {
    path: "/process/cirp/:id/case-summary",
    component: CirpSummaryPage,
  },
  {
    path: "/process/cirp/:id/case-runs",
    component: CirpCaseRun,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    path: "*", // This will handle 404
    component: NotFoundPage,
  },
];

export default routes;
